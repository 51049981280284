
.varausehdotContent {
    text-align: center;
    padding: 0 8%;
}

.varausehdotContent p {
    text-align: left;
    letter-spacing: normal;
    margin-bottom: 4em;
    line-height: 1.625;
}

.varausehdotContent h1 {
    margin-bottom: 2em;

}

.varausehdotContent h2 {
    margin-bottom: 1.1em;
    text-align: left;
}

#varausehdotd {
    height: 50px;
    width: 100%;
}

.varausehdotbody {
    height: 100%;
}
