body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  margin: 0;
  padding: 0;
  font-family: "montserrat",sans-serif;
  
}
.syoteicon{
  height: 100px;
  width: 150px;
  margin-left: 5%;
  
}

.navheader{
  height: 100px;
  background: #34495e;
  padding: 0 20px;
  color: #fff;
}

.syotelogo{
  line-height: 100px;
  float: left;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 30px;
}

.logo{
  text-align: center;
  padding: 4em;
  background-image: linear-gradient(to right, blue , white);
}
.menu{
  float: right;
  line-height: 100px;

}
.navItem{
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 2rem;
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  transition: 0.4s;
  cursor: pointer;
}

.show-menu-btn,.hide-menu-btn{
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.show-menu-btn{
  float: right;
}
.show-menu-btn i{
  line-height: 100px;
}
.menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover{
  color: #3498db;
}
#chk{
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}

@media screen and (max-width: 1300px){
  .show-menu-btn, .hide-menu-btn{
    display: block;
    margin-right: 4%;

  }

  .menu{
    
    position: fixed;
    width: 60%;
    height: 100vh;
    background: #333;
    right: -100%;
    top: 0;
    text-align: center;
    padding: 40px 0;
    
    line-height: normal;
    transition: 0.7s;
    overflow: scroll;

  }
 
  .menu a{
    font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    padding: 15px;
      margin-bottom: 2rem;

  }
.hide-menu-btn{
  position: absolute;
  top: 40px;
  right: 1%;
}
#chk:checked ~ .menu{
  right: 0;
}


}
.footer{
  width: 100%;
  height: 200px;
  margin-bottom: 0;
  bottom: 0;
  background: grey;
  
  
}
.Appdiv{
  background: linear-gradient(to bottom, #4b7aa3 , #cef7f4);
}



body {
    height: 1750px;
    max-width: 1350px;
    margin: 0 auto;
    font: 17px "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.homeContent{
    text-align: center;
    padding: 0 8%;
}

img{
    width: 100%;
    max-width: 600px;
    margin: 50px 0 ;
    box-shadow: 0 1.5em 2em 0.5em #4b7aa3;
    padding: 0 .5em .5em;
}

.boxx2{
    float: left;
}
.img2{

    width: 90%;
    object-fit: cover;
     max-width: 350px;
     height: 400px;
     margin: 50px 0 ;
     padding: 1em;
     box-shadow: 0 1.5em 2em 0.5em #4b7aa3;
}

.homeContent p{
    
    text-align: left;
    letter-spacing: normal;
    line-height: 1.625;
    margin-bottom: 1.8em;
}

.homeContent h2{
    margin-bottom: 0.5em;
    text-align: left;
}
.homeContent h1{
    text-align: left;
    margin-bottom: 2em;
}
#homed {
    height:50px; 
    width: 100%;  
   }





.activityContent{
    padding: 0 20px;
    text-align: center;
    padding-left: 8%;
    padding-right: 8%;
    
    
}
.activityContent p{
    text-align: left;
    
    letter-spacing: normal;
    line-height: 1.625;
    margin-bottom: 1.8em;
}
.activityContent h1{
    margin-bottom: 2em;
    
}
.activityContent h2{
margin-bottom: 1.1em;
text-align: left;
}
    #activityd {
        height:50px; 
        width: 100%;  
       }
    

.mokkiContent {
    padding: 0 8%;
}

#mokit .h1 {
    color: #000;
    font-weight: bold;
    margin-bottom: 1em;
}

.h2 {
    margin-bottom: 1em;
}

#moki {
    height: 60px;
    width: 100%;
}

.mokkiContent img {
    object-fit: cover;
    max-width: 350px;
    height: 337px;
    padding: .8em;

}

.mokkiContent .img2 {
    width: 100%;
    max-width: 600px;
    margin: 50px 0;
    box-shadow: 0 1.5em 2em 0.5em #4b7aa3;
    padding: 0 0.5em 0.5em 0.5em;
}


.mokkiContent p {
    text-align: left;
    letter-spacing: normal;
    line-height: 1.625;
    margin-bottom: 1.8em;
}


#yhteystiedot .p {

    line-height: 1.625;
}

.primary {
    width: 100%;
}

body {
    height: 100%;
}

#yhteystiedot .content {
    padding: 0 20px;
    zoom: 95%;
    text-align: justify;
    margin-left: 8%;
}

#yhteystiedotd {
    height: 80px;
    width: 100%;
}

#yhteystiedot .h2 {
    color: #000;
    font-weight: bold;
    margin-top: 80px;
    margin-bottom: 0.8125em;
}

#yhteystiedot .h1 {
    color: #000;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;


}

#yteystiedot .iframe {
    max-width: 100%;
    max-height: 700px;
}





body{
    height: 100%;
}
.hinnastoContent{
    padding: 0 20px;
    text-align: justify;
    padding-left: 10%;
    padding-right: 5%;

}
.nettiMokki {
    padding: 0 1% 0 10%;
}

.hinnastoContent h2{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
}
.viiva{

    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid black;
}
#hinnasto .h1{
        color: #000;
        font-weight: bold;
        margin-bottom: 3em;



}
.hinnastopoikkeukset{
    letter-spacing: normal;
    text-align: center;
    padding: 0 5% 0 10%;


}
.hinnastopoikkeukset p{
    text-align: justify;
    width: 100%;
    line-height: 1.8;
    font-weight: bold;
    margin-bottom: 5px;
}
#hinasto {

    height: 60px;
    width: 100%;
   }

.hinnastoContent p{

    text-align: center;
    padding: 1em;

}
.row1{
    width: 200px;

    text-align:center;
    letter-spacing: normal;

}
.pure-table{
    border: black;
    margin-bottom: 30px;
}
.tablebg2{
background: white;
height: 50px;

}
.tablebg1{
    background: #f2f2f2;
    height: 50px;
}

.tablebg3{
background: #34495e;
height: 50px;
}

.tablebg4{
background: #34495e;
height: 50px;
}
        
       


.varausehdotContent {
    text-align: center;
    padding: 0 8%;
}

.varausehdotContent p {
    text-align: left;
    letter-spacing: normal;
    margin-bottom: 4em;
    line-height: 1.625;
}

.varausehdotContent h1 {
    margin-bottom: 2em;

}

.varausehdotContent h2 {
    margin-bottom: 1.1em;
    text-align: left;
}

#varausehdotd {
    height: 50px;
    width: 100%;
}

.varausehdotbody {
    height: 100%;
}

