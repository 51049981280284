
#yhteystiedot .p {

    line-height: 1.625;
}

.primary {
    width: 100%;
}

body {
    height: 100%;
}

#yhteystiedot .content {
    padding: 0 20px;
    zoom: 95%;
    text-align: justify;
    margin-left: 8%;
}

#yhteystiedotd {
    height: 80px;
    width: 100%;
}

#yhteystiedot .h2 {
    color: #000;
    font-weight: bold;
    margin-top: 80px;
    margin-bottom: 0.8125em;
}

#yhteystiedot .h1 {
    color: #000;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;


}

#yteystiedot .iframe {
    max-width: 100%;
    max-height: 700px;
}



