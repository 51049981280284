
.mokkiContent {
    padding: 0 8%;
}

#mokit .h1 {
    color: #000;
    font-weight: bold;
    margin-bottom: 1em;
}

.h2 {
    margin-bottom: 1em;
}

#moki {
    height: 60px;
    width: 100%;
}

.mokkiContent img {
    object-fit: cover;
    max-width: 350px;
    height: 337px;
    padding: .8em;

}

.mokkiContent .img2 {
    width: 100%;
    max-width: 600px;
    margin: 50px 0;
    box-shadow: 0 1.5em 2em 0.5em #4b7aa3;
    padding: 0 0.5em 0.5em 0.5em;
}


.mokkiContent p {
    text-align: left;
    letter-spacing: normal;
    line-height: 1.625;
    margin-bottom: 1.8em;
}
