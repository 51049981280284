

.activityContent{
    padding: 0 20px;
    text-align: center;
    padding-left: 8%;
    padding-right: 8%;
    
    
}
.activityContent p{
    text-align: left;
    
    letter-spacing: normal;
    line-height: 1.625;
    margin-bottom: 1.8em;
}
.activityContent h1{
    margin-bottom: 2em;
    
}
.activityContent h2{
margin-bottom: 1.1em;
text-align: left;
}
    #activityd {
        height:50px; 
        width: 100%;  
       }
    