*{
  margin: 0;
  padding: 0;
  font-family: "montserrat",sans-serif;
  
}
.syoteicon{
  height: 100px;
  width: 150px;
  margin-left: 5%;
  
}

.navheader{
  height: 100px;
  background: #34495e;
  padding: 0 20px;
  color: #fff;
}

.syotelogo{
  line-height: 100px;
  float: left;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 30px;
}

.logo{
  text-align: center;
  padding: 4em;
  background-image: linear-gradient(to right, blue , white);
}
.menu{
  float: right;
  line-height: 100px;

}
.navItem{
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 2rem;
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  transition: 0.4s;
  cursor: pointer;
}

.show-menu-btn,.hide-menu-btn{
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.show-menu-btn{
  float: right;
}
.show-menu-btn i{
  line-height: 100px;
}
.menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover{
  color: #3498db;
}
#chk{
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}

@media screen and (max-width: 1300px){
  .show-menu-btn, .hide-menu-btn{
    display: block;
    margin-right: 4%;

  }

  .menu{
    
    position: fixed;
    width: 60%;
    height: 100vh;
    background: #333;
    right: -100%;
    top: 0;
    text-align: center;
    padding: 40px 0;
    
    line-height: normal;
    transition: 0.7s;
    overflow: scroll;

  }
 
  .menu a{
    font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    padding: 15px;
      margin-bottom: 2rem;

  }
.hide-menu-btn{
  position: absolute;
  top: 40px;
  right: 1%;
}
#chk:checked ~ .menu{
  right: 0;
}


}
.footer{
  width: 100%;
  height: 200px;
  margin-bottom: 0;
  bottom: 0;
  background: grey;
  
  
}
.Appdiv{
  background: linear-gradient(to bottom, #4b7aa3 , #cef7f4);
}
