
body{
    height: 100%;
}
.hinnastoContent{
    padding: 0 20px;
    text-align: justify;
    padding-left: 10%;
    padding-right: 5%;

}
.nettiMokki {
    padding: 0 1% 0 10%;
}

.hinnastoContent h2{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
}
.viiva{

    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid black;
}
#hinnasto .h1{
        color: #000;
        font-weight: bold;
        margin-bottom: 3em;



}
.hinnastopoikkeukset{
    letter-spacing: normal;
    text-align: center;
    padding: 0 5% 0 10%;


}
.hinnastopoikkeukset p{
    text-align: justify;
    width: 100%;
    line-height: 1.8;
    font-weight: bold;
    margin-bottom: 5px;
}
#hinasto {

    height: 60px;
    width: 100%;
   }

.hinnastoContent p{

    text-align: center;
    padding: 1em;

}
.row1{
    width: 200px;

    text-align:center;
    letter-spacing: normal;

}
.pure-table{
    border: black;
    margin-bottom: 30px;
}
.tablebg2{
background: white;
height: 50px;

}
.tablebg1{
    background: #f2f2f2;
    height: 50px;
}

.tablebg3{
background: #34495e;
height: 50px;
}

.tablebg4{
background: #34495e;
height: 50px;
}
        
       
