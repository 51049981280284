

body {
    height: 1750px;
    max-width: 1350px;
    margin: 0 auto;
    font: 17px "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.homeContent{
    text-align: center;
    padding: 0 8%;
}

img{
    width: 100%;
    max-width: 600px;
    margin: 50px 0 ;
    box-shadow: 0 1.5em 2em 0.5em #4b7aa3;
    padding: 0 .5em .5em;
}

.boxx2{
    float: left;
}
.img2{

    width: 90%;
    object-fit: cover;
     max-width: 350px;
     height: 400px;
     margin: 50px 0 ;
     padding: 1em;
     box-shadow: 0 1.5em 2em 0.5em #4b7aa3;
}

.homeContent p{
    
    text-align: left;
    letter-spacing: normal;
    line-height: 1.625;
    margin-bottom: 1.8em;
}

.homeContent h2{
    margin-bottom: 0.5em;
    text-align: left;
}
.homeContent h1{
    text-align: left;
    margin-bottom: 2em;
}
#homed {
    height:50px; 
    width: 100%;  
   }


